.leaderboard-container {
  max-width: 1200px;
  margin: 1.5rem 0;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--gray-900);
  font-weight: 600;
}

.leaderboard-table-container {
  overflow-x: auto;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9375rem;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 0.75rem 1rem;
  text-align: left;
}

.leaderboard-table th {
  background-color: var(--gray-50);
  font-weight: 600;
  color: var(--gray-700);
  border-bottom: 2px solid var(--gray-200);
}

.leaderboard-table td {
  border-bottom: 1px solid var(--gray-200);
}

.leaderboard-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard-table tr:hover td {
  background-color: var(--gray-50);
}

/* Highlight top performers */
.leaderboard-table tr:nth-child(1) td {
  background-color: rgba(43, 108, 176, 0.05);
  font-weight: 500;
}

.leaderboard-table tr:nth-child(2) td {
  background-color: rgba(43, 108, 176, 0.025);
}

.rank-column {
  width: 60px;
  text-align: center;
}

.rank-1, .rank-2, .rank-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-weight: 600;
  color: white;
}

.rank-1 {
  background-color: #ffd700; /* Gold */
}

.rank-2 {
  background-color: #c0c0c0; /* Silver */
}

.rank-3 {
  background-color: #cd7f32; /* Bronze */
}

.loading {
  text-align: center;
  padding: 3rem;
  color: var(--gray-600);
}

.error {
  text-align: center;
  padding: 2rem;
  font-size: 18px;
  color: #9b2c2c;
  background-color: #fed7d7;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.empty-message {
  text-align: center;
  padding: 2rem;
  color: var(--gray-600);
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .leaderboard-container {
    padding: 1.5rem 1rem;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 0.625rem 0.5rem;
    font-size: 0.875rem;
  }
}