.admin-container {
  max-width: 800px;
  margin: 2rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  padding: 2rem;
}

.admin-container h2 {
  color: var(--gray-900);
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  border-bottom: 2px solid var(--gray-200);
  padding-bottom: 0.75rem;
}

.current-settings {
  background-color: var(--gray-50);
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  padding: 1.25rem;
  margin-bottom: 2rem;
}

.current-settings h3 {
  color: var(--gray-800);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.settings-info p {
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px dashed var(--gray-200);
}

.no-settings {
  color: var(--gray-500);
  font-style: italic;
}

.settings-form {
  background-color: var(--gray-50);
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  padding: 1.25rem;
}

.settings-form h3 {
  color: var(--gray-800);
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--gray-700);
}

.form-group input {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid var(--gray-300);
  border-radius: 0.375rem;
  font-size: 0.9375rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-group input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.2);
}

.form-group small {
  display: block;
  margin-top: 0.375rem;
  color: var(--gray-500);
  font-size: 0.75rem;
}

.admin-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.admin-button:hover {
  background-color: var(--primary-dark);
}

.admin-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.success-message {
  background-color: #c6f6d5;
  color: #276749;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
}

.error-message {
  background-color: #fed7d7;
  color: #9b2c2c;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.admin-header h2 {
  margin: 0;
}

.refresh-button {
  background-color: var(--gray-200);
  color: var(--gray-700);
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.refresh-button:hover {
  background-color: var(--gray-300);
}

.refresh-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-message {
  text-align: center;
  padding: 2rem;
  color: var(--gray-600);
  font-style: italic;
  background-color: var(--gray-50);
  border-radius: 0.5rem;
  border: 1px dashed var(--gray-300);
}