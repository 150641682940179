.stickman-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  margin: 2rem auto;
  max-width: 800px;
}

.stickman-page h2 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.weightlifter-container {
  margin: 2rem 0;
  cursor: pointer;
}

/* Keep some of the weightlifter animation but simplify colors */
.weightlifter {
  position: relative;
  width: 200px;
  height: 200px;
}

.head, .body, .arm, .leg {
  background-color: var(--primary-color);
  position: absolute;
}

.barbell, .weight {
  background-color: var(--gray-800);
  position: absolute;
}

/* Animations */
.animated {
  animation: lift 1.5s ease-in-out infinite alternate;
  cursor: pointer;
  transform-origin: center;
}

@keyframes lift {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

/* Additional animation for arm movement */
.animated .left-arm, .animated .right-arm {
  animation: flex 1s infinite alternate;
  transform-origin: top center;
}

@keyframes flex {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

.click-counter {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: var(--gray-600);
}

.secret-message {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: var(--gray-50);
  border: 2px dashed var(--primary-color);
  border-radius: 0.5rem;
  max-width: 500px;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #ffd700;
  }
  100% {
    box-shadow: 0 0 20px #ffd700;
  }
}

.secret-message p {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: var(--gray-800);
}

.stickman-navigation {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.back-link {
  padding: 0.625rem 1.25rem;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
  font-weight: 500;
}

.back-link:hover {
  background-color: var(--primary-dark);
}