.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.register-form-container {
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  padding: 2.5rem;
  width: 100%;
  max-width: 24rem;
}

.register-form-container h2 {
  text-align: center;
  color: var(--gray-900);
  margin-bottom: 1.5rem;
}

.register-form .form-group {
  margin-bottom: 1.25rem;
}

.register-form label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #555;
}

.register-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.register-form input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.register-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.register-button:hover {
  background-color: var(--primary-dark);
}

.register-button:disabled {
  background-color: var(--gray-400);
  cursor: not-allowed;
}

.register-footer {
  margin-top: 1.5rem;
  text-align: center;
}

.register-footer p {
  color: var(--gray-600);
  margin-bottom: 0.5rem;
}

.register-footer a {
  color: var(--primary-color);
  font-weight: 500;
}

.register-footer a:hover {
  text-decoration: underline;
}

.error-message {
  background-color: #fed7d7;
  color: #9b2c2c;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
}

/* Add this to your existing CSS */

.secret-question {
  margin-top: 1.5rem;
  border-top: 1px solid var(--gray-200);
  padding-top: 1.5rem;
  position: relative;
}

.secret-question::before {
  content: "🔐";
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white);
  padding: 0 0.5rem;
  font-size: 1.25rem;
}

.secret-question label {
  font-weight: 600;
  color: var(--gray-800);
}

.secret-question input {
  border-color: var(--primary-light);
}

.secret-question input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.25);
}