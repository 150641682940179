.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding: 1rem 2rem;
  box-shadow: var(--shadow);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-section {
  display: flex;
  align-items: center;
}

.left {
  flex: 1;
  justify-content: flex-start;
}

.center {
  flex: 2;
  justify-content: center;
}

.right {
  flex: 1;
  justify-content: flex-end;
  gap: 1.5rem;
}

.nav-brand {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: -0.5px;
  text-decoration: none;
}

.nav-link {
  color: var(--gray-700);
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.nav-link:hover {
  background-color: var(--gray-100);
  color: var(--primary-color);
}

.nav-link.active {
  color: var(--primary-color);
  font-weight: 600;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.username {
  color: var(--gray-700);
  font-weight: 500;
}

.logout-button {
  background: none;
  border: 1px solid var(--gray-300);
  color: var(--gray-600);
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.logout-button:hover {
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-color: var(--gray-400);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 0.75rem 1rem;
  }
  
  .navbar-section {
    width: 100%;
    justify-content: center;
    margin: 0.25rem 0;
  }
  
  .right {
    gap: 0.75rem;
  }
  
  .nav-brand {
    margin: 0.5rem 0;
  }
}