.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 300px); /* Adjust based on header height */
  background-color: transparent;
  z-index: 10;
  position: relative;
}

.login-form-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 400px;
  z-index: 15;
}

h2 {
  text-align: center;
  margin-bottom: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.login-form label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #555;
}

.login-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  z-index: 20;
  position: relative;
  pointer-events: auto;
}

.login-form input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-button:hover {
  background-color: #3a7bc8;
}

.login-footer {
  margin-top: 20px;
  text-align: center;
}

.login-footer a {
  color: #4a90e2;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}