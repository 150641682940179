.my-lifts-container {
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  padding: 2rem;
  margin: 1.5rem 0;
}

.my-lifts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.my-lifts-header h2 {
  color: var(--gray-900);
  margin: 0;
}

.add-pr-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-pr-button:hover {
  background-color: var(--primary-dark);
}

.cancel-button {
  background-color: var(--gray-400);
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: var(--gray-600);
}

.add-pr-form-container {
  background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.add-pr-form-container h3 {
  color: var(--gray-800);
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1.125rem;
}

.add-pr-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.add-pr-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--gray-700);
}

.add-pr-form input,
.add-pr-form textarea,
.add-pr-form select {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid var(--gray-300);
  border-radius: 0.375rem;
  font-size: 0.9375rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.add-pr-form input:focus,
.add-pr-form textarea:focus,
.add-pr-form select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.2);
}

.add-pr-form textarea {
  resize: vertical;
  min-height: 80px;
}

.submit-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.submit-button:hover {
  background-color: var(--primary-dark);
}

/* Category and PR cards styling */
.category-section {
  margin-bottom: 2rem;
}

.category-title {
  color: var(--gray-800);
  border-bottom: 2px solid var(--gray-200);
  padding-bottom: 0.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.pr-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
}

.pr-card {
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-shadow: var(--shadow-sm);
  transition: transform 0.2s, box-shadow 0.2s;
}

.pr-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.exercise-name {
  font-weight: 600;
  font-size: 1.0625rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.pr-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.pr-stat {
  display: flex;
  flex-direction: column;
}

.pr-label {
  font-size: 0.75rem;
  color: var(--gray-600);
  margin-bottom: 0.25rem;
}

.pr-value {
  font-weight: 600;
  color: var(--gray-800);
}

.pr-notes {
  border-top: 1px solid var(--gray-200);
  padding-top: 0.75rem;
  margin-top: 0.5rem;
}

.notes-label {
  font-size: 0.75rem;
  color: var(--gray-600);
  display: block;
  margin-bottom: 0.25rem;
}

.pr-notes p {
  font-size: 0.875rem;
  color: var(--gray-700);
  margin: 0;
  line-height: 1.4;
}

.empty-records {
  text-align: center;
  padding: 3rem 1rem;
  color: var(--gray-600);
}

.loading {
  text-align: center;
  padding: 3rem 1rem;
  color: var(--gray-600);
}

.error-message {
  background-color: #fed7d7;
  color: #9b2c2c;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
}

/* Add these styles to your existing CSS */

.header-buttons {
  display: flex;
  gap: 0.75rem;
}

.refresh-button {
  background-color: var(--gray-200);
  color: var(--gray-700);
  border: none;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: var(--gray-300);
}

.refresh-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Date input styling */
input[type="date"] {
  font-family: 'Inter', sans-serif;
  padding: 0.625rem;
  border: 1px solid var(--gray-300);
  border-radius: 0.375rem;
  width: 100%;
  transition: border-color 0.2s, box-shadow 0.2s;
  color: var(--gray-800);
}

input[type="date"]:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.2);
}

/* Adjust appearance for better consistency across browsers */
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.2s;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .my-lifts-container {
    padding: 1.5rem 1rem;
  }
  
  .my-lifts-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .add-pr-button, 
  .cancel-button {
    width: 100%;
  }
  
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
  
  .pr-cards {
    grid-template-columns: 1fr;
  }

  .header-buttons {
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .add-pr-button, 
  .cancel-button,
  .refresh-button {
    width: 100%;
  }
}