/* Modern Minimalist Theme with Royal Blue Accents */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #2b6cb0; /* Royal blue */
  --primary-light: #4299e1;
  --primary-dark: #1a4971;
  --gray-100: #f7fafc;
  --gray-200: #edf2f7;
  --gray-300: #e2e8f0;
  --gray-400: #cbd5e0;
  --gray-500: #a0aec0;
  --gray-600: #718096;
  --gray-700: #4a5568;
  --gray-800: #2d3748;
  --gray-900: #1a202c;
  --white: #ffffff;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--gray-100);
  color: var(--gray-800);
  line-height: 1.5;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: var(--gray-900);
  margin-bottom: 0.75rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: var(--primary-light);
}

button {
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

/* Content Container */
.content-container {
  flex: 1;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

/* Under Construction Page */
.under-construction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--shadow-md);
  padding: 3rem 2rem;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

.under-construction h2 {
  color: var(--gray-700);
  margin-bottom: 1rem;
}

.under-construction:after {
  content: "🏗️";
  font-size: 48px;
  margin-top: 20px;
}

/* Form Styling */
.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--gray-700);
}

input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--gray-300);
  border-radius: 0.375rem;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.2);
}

/* Button Styling */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s;
  border: none;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  box-shadow: var(--shadow);
}

.btn-secondary {
  background-color: var(--gray-200);
  color: var(--gray-800);
}

.btn-secondary:hover {
  background-color: var(--gray-300);
}

/* Error Message */
.error-message {
  background-color: #fed7d7;
  color: #9b2c2c;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
}

/* Easter Egg Hint - Still Subtle */
.easter-egg-hint {
  font-size: 8px;
  color: var(--gray-300);
  position: fixed;
  bottom: 8px;
  right: 8px;
  text-decoration: none;
}

.easter-egg-hint:hover {
  color: var(--gray-500);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .content-container {
    padding: 1.5rem 1rem;
  }
}

.App {
  text-align: center;
  background: linear-gradient(135deg, var(--deep-space) 0%, var(--space-glow) 100%);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* Stars effect */
.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(var(--star-white) 1px, transparent 1px),
    radial-gradient(var(--star-white) 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
  animation: twinkle 10s infinite alternate;
  opacity: 0.5;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 0.8; }
}

/* Remove or modify the App-header styling since we now have navbar */
.App-header {
  display: none; /* Hide the header since navbar replaces it */
}

.neon-title {
  font-family: 'Press Start 2P', cursive;
  color: var(--neon-pink);
  font-size: 3.5rem;
  text-shadow: 0 0 10px var(--neon-pink), 
               0 0 20px var(--neon-pink), 
               0 0 30px var(--neon-pink);
  margin: 0;
  letter-spacing: 2px;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0%, 100% { text-shadow: 0 0 10px var(--neon-pink), 0 0 20px var(--neon-pink); }
  50% { text-shadow: 0 0 15px var(--neon-pink), 0 0 30px var(--neon-pink), 0 0 45px var(--neon-pink); }
}

.retro-text {
  font-family: 'VT323', monospace;
  color: var(--neon-blue);
  font-size: 1.8rem;
  max-width: 600px;
  line-height: 1.6;
  background-color: rgba(10, 11, 26, 0.7);
  padding: 2rem;
  border-radius: 10px;
  border: 2px solid var(--neon-blue);
  box-shadow: 0 0 15px var(--neon-blue);
}

.kitten-container {
  margin-top: 3rem;
  position: relative;
}

.space-kitten {
  width: 150px;
  height: 150px;
  background-color: #f4f4f4;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid var(--neon-purple);
  box-shadow: 0 0 20px var(--neon-purple);
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

/* Add a cute kitten silhouette */
.space-kitten::before {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 100px;
  background-color: #333;
  border-radius: 50% 50% 0 0;
}

/* Kitten ears */
.space-kitten::after {
  content: '';
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 30px;
  background-color: #333;
  border-radius: 50% 50% 0 0;
  box-shadow: -30px -15px 0 #333, 30px -15px 0 #333;
}

.terminal-section {
  margin-top: 3rem;
  width: 90%;
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 1rem;
  border: 2px solid var(--neon-blue);
  font-family: 'VT323', monospace;
  color: var(--neon-blue);
}

.terminal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--neon-pink);
  padding-bottom: 0.5rem;
}

.terminal-body {
  text-align: left;
  padding: 1rem;
  white-space: pre-wrap;
}

.terminal-body::before {
  content: '$ ';
  color: var(--neon-pink);
}

.blink {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.start-btn {
  font-family: 'Press Start 2P', cursive;
  background: linear-gradient(to right, var(--neon-pink), var(--neon-purple));
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2rem;
  box-shadow: 0 0 15px var(--neon-pink);
  transition: all 0.3s;
}

.start-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px var(--neon-pink);
}

/* Completely revised stick figure weightlifter */

.weightlifter-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 20px auto;
}

.weightlifter {
  position: relative;
  width: 100%;
  height: 100%;
  animation: body-squat 2s infinite alternate;
}

@keyframes body-squat {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Stick Figure Body Parts */
.head {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--neon-blue);
  box-shadow: 0 0 10px var(--neon-blue);
  z-index: 2;
}

.body {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 50px;
  background-color: var(--neon-blue);
  box-shadow: 0 0 10px var(--neon-blue);
}

/* Completely revised arms both aiming upward */
.arm {
  position: absolute;
  width: 60px;
  height: 5px;
  background-color: var(--neon-blue);
  box-shadow: 0 0 10px var(--neon-blue);
  top: 65px;
  z-index: 3;
}

.left-arm {
  left: 50%;
  transform-origin: left center;
  transform: rotate(-115deg);
  animation: left-arm-lift 2s infinite alternate;
}

.right-arm {
  left: 50%;
  transform-origin: left center;
  transform: rotate(-50deg);
  animation: right-arm-lift 2s infinite alternate;
}

@keyframes left-arm-lift {
  0% {
    transform: rotate(-135deg);
  }
  100% {
    transform: rotate(-130deg);
  }
}

@keyframes right-arm-lift {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-50deg);
  }
}

/* Legs with knee bend */
.leg {
  position: absolute;
  width: 5px;
  height: 35px;
  background-color: var(--neon-blue);
  box-shadow: 0 0 10px var(--neon-blue);
  top: 110px;
  transform-origin: top center;
}

.left-leg {
  left: 47%;
  transform: rotate(15deg);
  animation: left-knee-bend 2s infinite alternate;
}

.right-leg {
  left: 53%;
  transform: rotate(-15deg);
  animation: right-knee-bend 2s infinite alternate;
}

@keyframes left-knee-bend {
  0% {
    height: 30px;
    transform: rotate(20deg);
  }
  100% {
    height: 35px;
    transform: rotate(15deg);
  }
}

@keyframes right-knee-bend {
  0% {
    height: 30px;
    transform: rotate(-20deg);
  }
  100% {
    height: 35px;
    transform: rotate(-15deg);
  }
}

/* Add lower legs that bend in the opposite direction */
.left-leg::after,
.right-leg::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 35px;
  background-color: var(--neon-blue);
  box-shadow: 0 0 10px var(--neon-blue);
  bottom: -33px;
  left: 0;
  transform-origin: top center;
}

.left-leg::after {
  transform: rotate(-30deg);
  animation: left-calf-bend 2s infinite alternate;
}

.right-leg::after {
  transform: rotate(30deg);
  animation: right-calf-bend 2s infinite alternate;
}

@keyframes left-calf-bend {
  0% {
    transform: rotate(-40deg);
    height: 32px;
  }
  100% {
    transform: rotate(-30deg);
    height: 35px;
  }
}

@keyframes right-calf-bend {
  0% {
    transform: rotate(40deg);
    height: 32px;
  }
  100% {
    transform: rotate(30deg);
    height: 35px;
  }
}

/* Barbell and Weights - positioned overhead */
.barbell {
  position: absolute;
  width: 90px;
  height: 5px;
  background-color: var(--neon-purple);
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px var(--neon-purple);
  animation: barbell-press 2s infinite alternate;
  z-index: 1;
}

@keyframes barbell-press {
  0% {
    top: 25px;
  }
  100% {
    top: 15px;
  }
}

.weight {
  position: absolute;
  width: 15px;
  height: 25px;
  background-color: var(--neon-pink);
  box-shadow: 0 0 10px var(--neon-pink);
  border-radius: 3px;
  animation: weight-press 2s infinite alternate;
}

@keyframes weight-press {
  0% {
    top: 15px;
  }
  100% {
    top: 5px;
  }
}

.left-weight {
  left: 15px;
  top: 5px;
}

.right-weight {
  right: 15px;
  top: 5px;
}

/* Add a glow effect under the weightlifter */
.weightlifter::after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 10px;
  background-color: var(--neon-blue);
  filter: blur(10px);
  border-radius: 50%;
  opacity: 0.5;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    opacity: 0.3;
    width: 80px;
  }
  100% {
    opacity: 0.5;
    width: 100px;
  }
}

/* Make it responsive */
@media (max-width: 768px) {
  .weightlifter-container {
    width: 150px;
    height: 150px;
  }
}

/* Add at the end of your App.css file */

.dashboard {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.welcome-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/* Add at the bottom of your CSS file */

/* Easter egg hint - tiny barely visible link in footer */
.easter-egg-hint {
  font-size: 8px;
  color: rgba(255, 255, 255, 0.1);
  position: fixed;
  bottom: 5px;
  right: 5px;
  text-decoration: none;
}

.easter-egg-hint:hover {
  color: rgba(255, 255, 255, 0.3);
}

/* Add this to your existing App.css */

.under-construction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  text-align: center;
}

.under-construction:after {
  content: "🏗️";
  font-size: 48px;
  margin-top: 20px;
}

/* Update your content-container styling */

.content-container {
  padding: 20px;
  padding-top: 30px; /* Add more space at top due to navbar */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}