.competition-container {
  max-width: 1000px;
  margin: 2rem auto;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-md);
  padding: 2rem;
  position: relative;
}

.competition-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  border-bottom: 2px solid var(--gray-200);
  padding-bottom: 1rem;
}

.header-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem;
}

.refresh-button {
  background-color: var(--gray-200);
  color: var(--gray-700);
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.refresh-button:hover {
  background-color: var(--gray-300);
}

.refresh-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  color: var(--gray-600);
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 10;
  border-radius: 0.5rem;
  margin-top: 4rem;
}

.loading-spinner {
  border: 4px solid var(--gray-200);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.competition-header h2 {
  color: var(--gray-900);
  margin: 0;
}

.competition-info {
  color: var(--gray-600);
  text-align: right;
}

.competition-info p {
  margin: 0.25rem 0;
}

.competition-results, .exercise-improvements {
  margin-bottom: 2.5rem;
}

.competition-results h3, .exercise-improvements h3 {
  color: var(--gray-800);
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
}

.user-rankings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-rank-card {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  transition: transform 0.2s, box-shadow 0.2s;
}

.user-rank-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.first-place {
  background-color: #fef3c7;
  border-color: #f59e0b;
}

.second-place {
  background-color: #f1f5f9;
  border-color: #94a3b8;
}

.third-place {
  background-color: #fee2e2;
  border-color: #ef4444;
}

.rank-position {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.25rem;
  margin-right: 1.25rem;
}

.first-place .rank-position {
  background-color: #f59e0b;
}

.second-place .rank-position {
  background-color: #94a3b8;
}

.third-place .rank-position {
  background-color: #ef4444;
}

.user-rank-info {
  flex: 1;
}

.user-rank-name {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  color: var(--gray-900);
}

.user-rank-stats {
  display: flex;
  gap: 1.5rem;
}

.rank-stat {
  display: flex;
  flex-direction: column;
}

.stat-label {
  font-size: 0.75rem;
  color: var(--gray-600);
  margin-bottom: 0.25rem;
}

.stat-value {
  font-weight: 600;
  color: var(--gray-900);
}

.user-exercise-section {
  margin-bottom: 2.5rem;
}

.user-exercise-header {
  color: var(--gray-800);
  margin-bottom: 1rem;
  font-size: 1.125rem;
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 0.5rem;
}

.exercise-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.exercise-improvement-card {
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: transform 0.2s, box-shadow 0.2s;
}

.exercise-improvement-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.exercise-name {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1rem;
  font-weight: 600;
}

.improvement-details {
  padding: 1rem;
}

.improvement-column {
  margin-bottom: 1rem;
}

.improvement-column h5, .improvement-result h5 {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: var(--gray-600);
  margin: 0 0 0.375rem 0;
}

.pr-detail {
  font-size: 0.875rem;
  color: var(--gray-800);
  margin-bottom: 0.25rem;
}

.improvement-result {
  border-top: 1px dashed var(--gray-200);
  padding-top: 0.75rem;
}

.improvement-value {
  font-weight: 600;
  color: #2f855a;
  font-size: 1rem;
}

.improvement-percent {
  font-size: 0.875rem;
  color: #38a169;
}

.no-improvements {
  grid-column: 1 / -1;
  padding: 2rem;
  text-align: center;
  color: var(--gray-500);
  font-style: italic;
  background-color: var(--gray-50);
  border-radius: 0.5rem;
}

/* Badge for starting PRs */
.competition-start-badge {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background-color: #fef3c7;
  color: #92400e;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
}

/* Add to your existing PR card */
.pr-card {
  position: relative;
}

@media (max-width: 768px) {
  .competition-container {
    padding: 1.5rem 1rem;
  }
  
  .competition-header {
    flex-direction: column;
  }
  
  .header-actions {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    margin-top: 1rem;
  }
  
  .competition-info {
    text-align: left;
    margin-top: 0.75rem;
  }
  
  .user-rank-stats {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .exercise-cards {
    grid-template-columns: 1fr;
  }
}